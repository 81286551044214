










































import { Vue, Component, Prop } from "vue-property-decorator";
import adminService from "@/services/AdminService";
import { organisationModule } from "@/store/modules/OrganisationModule";

@Component
export default class Analyze extends Vue {
  public suppliers: any = [];
  public supplierId: string = null;
  public fromDate: string = new Date().toISOString().substring(0, 10);
  public onlyActive: boolean = true;

  private async getSuppliers() {
    this.suppliers = await adminService.getSuppliers(this.onlyActive, organisationModule.organisation.id);
    this.supplierId = this.suppliers[0].id;
  }

  public async activated() {
    await this.getSuppliers();
  }

  private async analyze() {
    await adminService.analyze(
      organisationModule.organisation.id,
      this.supplierId,
      this.fromDate
    );
  }
}
